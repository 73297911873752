import { graphql } from "gatsby"
import React from "react"
import { BlogList } from "../components/blog/blog-list"

const Blog = props => {
  return <BlogList {...props} />
}

export default Blog

export const query = graphql`
  query($limit: Int!, $skip: Int!) {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            subtitle
            title
            featuredImage {
              childImageSharp {
                id
                fluid {
                  src
                }
              }
            }
            category
            tags
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
            category
            tags
          }
        }
      }
    }
    tags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              tags
            }
            frontmatter {
              tags
            }
          }
        }
      }
    }
    latest_posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              category
            }
            frontmatter {
              title
            }
          }
        }
      }
      nodes {
        frontmatter {
          title
          category
        }
      }
    }
  }
`
